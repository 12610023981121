<template>
  <div style="width:1200px;margin:0 auto;padding:20px">
    <!--<div v-if="pagesName !== 'MyOrderInfo'">-->
    <!--    <el-breadcrumb separator="/" v-if="pagesName === 'BiddingPaymented'">-->
    <!--        <el-breadcrumb-item :to="{ path: '/home/HomePage' }">首页</el-breadcrumb-item>-->
    <!--        <el-breadcrumb-item :to="{ path: '/home/Bidding' }">竞卖中心</el-breadcrumb-item>-->
    <!--        <el-breadcrumb-item>成功下单</el-breadcrumb-item>-->
    <!--    </el-breadcrumb>-->
    <!--    <el-breadcrumb separator="/" v-else>-->
    <!--        <el-breadcrumb-item :to="{ path: '/home/HomePage' }">首页</el-breadcrumb-item>-->
    <!--        <el-breadcrumb-item :to="{ path: '/home/Mall' }">商品超市</el-breadcrumb-item>-->
    <!--        <el-breadcrumb-item>成功下单</el-breadcrumb-item>-->
    <!--    </el-breadcrumb>-->
    <div />
    <div class="order-status">
      <div class="order-status-box">
        <div style="color:#56C075;font-size:20px;margin-bottom:20px">
          <i class="el-icon-success"></i>
          订单提交成功
        </div>
        <div class="box-item">
          <div class="title">
            订单号：
          </div>
          <div class="text">{{ orderInfo.orderSn }}</div>
        </div>
        <div class="box-item">
          <div class="title">
            订单状态：
          </div>
          <div class="text">{{ orderStatusText }}</div>
        </div>
        <div class="box-item">
          <div class="title">
            订单金额：
          </div>
          <div class="text" style="color:#FF0033; ">{{ orderInfo.orderPrice }}</div>
          元
        </div>
        <p v-if="orderStatus == 'DQS'" style="padding:10px 20px;color:red;cursor:pointer;" @click="toContractDetail">订单提交成功，请及时完成合同签署</p>
      </div>
      <div class="order-status-box" style="padding-top:66.67px;">
        <div class="box-item" v-if="orderInfo.shipChannel">
          <div class="title">
            物流公司：
          </div>
          <div class="text">{{ orderInfo.shipChannel }}</div>
        </div>
        <div class="box-item" v-if="orderInfo.shipSn">
          <div class="title">
            物流号/车牌号：
          </div>
          <div class="text">{{ orderInfo.shipSn }}</div>
        </div>
      </div>
      <!--<p style="padding:10px 20px">注意：请及时联系卖家付款及交收货物，下单后<span-->
      <!--style="color:red;">2个小时内</span>联系卖家，如不及时联系，钢材超市有权取消订单</p>-->
    </div>
    <!--</div>-->

    <div>
      <h1>订单流程</h1>
      <div v-if="['QXC', 'QXS'].indexOf(orderInfo.orderStatus) < 0" style="background:#fff;padding:20px 20px 10px">
        <el-steps :active="flowStep" align-center>
          <el-step title="确认订单"></el-step>
          <el-step title="合同签署"></el-step>
          <el-step title="买家支付"></el-step>
          <el-step title="供应商确认收款、发货、开发票"></el-step>
          <el-step title="买家签收"></el-step>
          <!-- <el-step title="流程结束"></el-step> -->
        </el-steps>
      </div>
      <div v-else style="padding: 24px;background: #fff;color: #333333;font-size: 14px;line-height: 24px;">
        {{ orderInfo.orderStatu === 'QXC' ? '订单取消待确认' : '订单已取消成功' }}
      </div>
    </div>
    <div>
      <h1>订单信息</h1>
      <div style="background:#fff;padding:10px 20px 40px;">
        <h3>{{ customer === 'seller' ? '卖方' : '供应商' }}信息：</h3>
        <div v-for="(item, index) in brands" :key="index">
          <div style="display:flex;margin-bottom:10px;margin-top:10px">
            <div style="flex:0 0 80px;text-align:right;color:#999">{{ customer === 'seller' ? '卖方' : '供应商' }}：</div>
            <div style="flex:0 0 500px;">{{ item.brandName }}</div>
          </div>
          <div style="display:flex;margin-bottom:10px">
            <div style="flex:0 0 80px;text-align:right;color:#999">联系人：</div>
            <div>{{ item.userName }}</div>
          </div>
          <div style="display:flex;margin-bottom:10px">
            <div style="flex:0 0 80px;text-align:right;color:#999">电话：</div>
            <div>{{ item.phone }}</div>
          </div>
          <div class="border" style="display:flex;padding-bottom:30px">
            <div style="flex:0 0 80px;text-align:right;color:#999">地址：</div>
            <div style="flex:0 0 500px;">
              {{ item.address }}
            </div>
          </div>
          <div class="dash "></div>
        </div>

        <h3>买方信息：</h3>
        <div>
          <div style="display:flex;margin-bottom:10px;margin-top:10px">
            <div style="flex:0 0 80px;text-align:right;color:#999">买方：</div>
            <div style="flex:0 0 500px;">{{ orderInfo.clientInfoName }}</div>
          </div>
          <div style="display:flex;margin-bottom:10px;align-items:center">
            <div style="flex:0 0 80px;text-align:right;color:#999">联系人：</div>
            <div style="flex:0 0 500px;">{{ orderInfo.consignee }}</div>
          </div>
          <div style="display:flex;margin-bottom:10px;align-items:center">
            <div style="flex:0 0 80px;text-align:right;color:#999">电话：</div>
            <div style="flex:0 0 500px;">{{ orderInfo.mobile }}</div>
          </div>
          <div class="border" style="display:flex;padding-bottom:30px;align-items:center">
            <div style="flex:0 0 80px;text-align:right;color:#999">地址：</div>
            <div style="flex:0 0 500px;">{{ orderInfo.address }}</div>
          </div>
        </div>

        <h3>商品信息</h3>
        <div style="position:relative;padding-bottom:90px;" class="border">
          <el-table :data="tableData" style="width: 100%;" border fit :header-cell-style="{background: '#F5f5f5', height: '22px', 'line-height': '22px', color: '#999'}">
            <el-table-column prop="goodsSn" label="货物" min-width="95"> </el-table-column>
            <el-table-column prop="market" label="所在仓库" min-width="180"> </el-table-column>
            <el-table-column prop="goodsName" label="品名" min-width="100"> </el-table-column>
            <el-table-column prop="specifications" label="规格" min-width="120" :formatter="formatData"> </el-table-column>
            <el-table-column prop="stuff" label="材质" min-width="95"> </el-table-column>
            <el-table-column prop="manufacturer" label="钢厂" min-width="95"> </el-table-column>
            <el-table-column prop="number" label="数量" min-width="85"> </el-table-column>
            <!--<el-table-column prop="weightWay" label="计算方式" width="95">-->
            <!--</el-table-column>-->
            <el-table-column prop="weight" label="重量(吨)" min-width="95" :formatter="formatWeight"> </el-table-column>
            <el-table-column prop="processCharge" label="加工费" min-width="95"> </el-table-column>
            <el-table-column prop="price" label="单价(元)" min-width="120"> </el-table-column>
            <el-table-column prop="totalPrice" label="金额(元)" min-width="120"> </el-table-column>
          </el-table>

          <div style="position:absolute;right:0px;bottom:20px;font-size:14px">
            <span>合计：</span>
            <span style="margin-left:10px">{{ orderInfo.orderPrice }}元</span>
          </div>
        </div>
        <div class="dash "></div>
        <div style="position:relative;height:40px;">
          <div style="position:absolute;right:0;top:20px;font-size:18px">
            <span>应付总计：</span>
            <span style="color:#FC4500;font-size:18px;margin-left:10px">{{ orderInfo.orderPrice }}</span> 元
          </div>
        </div>
      </div>
    </div>

    <div style="padding:0 20px 20px;background:#fff">
      <div style="margin:0 auto;width:390px">
        <router-link :to="'/User/MyOrder?customer=' + this.customer" v-if="pagesName == 'MyOrderInfo'">
          <el-button style="margin-right:20px">返回订单列表</el-button>
        </router-link>
        <el-button @click="goHome" style="margin-right:20px" v-else>返回首页</el-button>
        <el-button @click="printBtn" type="primary">打印</el-button>
        <el-button v-if="orderStatus == 'DQS'" @click="toContractDetail" type="primary">合同签署</el-button>
      </div>
    </div>

    <div
      class="invoice-box"
      v-if="
        isHandleShow &&
          customer == 'sellder' &&
          ((orderInfo.orderStatus == 'DFH' && (orderInfo.orderSn.indexOf('TS') != -1 || orderInfo.orderSn.indexOf('AU') != -1)) || orderInfo.orderStatus == 'DKP')
      "
    >
      <h2>开票信息</h2>
      <div class="invoice-content">
        <div class="invoice-item">
          <span class="title">单位名称：</span>
          {{ orderInfo.clientInfoName || '--' }}
        </div>
        <div class="invoice-item">
          <span class="title">开户行：</span>
          {{ invoiceInfo.accountBank || '--' }}
        </div>
        <div class="invoice-item">
          <span class="title">开户行账号：</span>
          {{ invoiceInfo.accountNo || '--' }}
        </div>
        <div class="invoice-item">
          <span class="title">纳税号：</span>
          {{ invoiceInfo.payTaxesNo || '--' }}
        </div>
      </div>
    </div>

    <div class="operate" v-if="isHandleShow">
      <h2 style="margin-bottom:10px">操作</h2>
      <div class="content">
        <div class="btn-box">
          <!--买家 支付或者取消订单-->
          <!-- <span class="image-name" v-if="imageName">{{imageName}}</span>  -->
          <p class="tit" v-if="customer == 'buyder' && orderInfo.orderStatus == 'DZF'">
            <span class="required">*</span>上传支付凭证：<span class="image-name" v-if="imageName">{{ imageName }}</span>
          </p>
          <p class="tit" v-if="customer == 'buyder' && orderInfo.orderStatus == 'DSH'">
            <span class="required">*</span>上传签收凭证：<span class="image-name" v-if="imageName">{{ imageName }}</span>
          </p>

          <!--卖家 发货,开发票，确认收款，确认取消订单-->
          <p class="tit" v-if="customer == 'sellder' && orderInfo.orderStatus == 'DFH'">
            <span class="required">*</span>上传发货凭证：<span class="image-name" v-if="imageName">{{ imageName }}</span>
          </p>
          <p class="tit" v-if="customer == 'sellder' && orderInfo.orderStatus == 'DKP'">
            <span class="required">*</span>上传开票凭证：<span class="image-name" v-if="imageName">{{ imageName }}</span>
          </p>

          <!--                    <p class="tit" v-if="param.fileUrl">-->
          <!--                        <span class="image-name">{{param.fileName}}</span>-->
          <!--                    </p>-->

          <div class="uplaod-box">
            <el-upload
              class="upload-demo"
              :action="uploadPath"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :before-remove="beforeRemove"
              :before-upload="beforeUpload"
              :on-success="handleSuccess"
              multiple
              :on-exceed="handleExceed"
              :file-list="fileList"
              submit
              v-if="
                (customer === 'sellder' && (orderInfo.orderStatus === 'DFH' || orderInfo.orderStatus === 'DKP')) ||
                  (customer === 'buyder' && (orderInfo.orderStatus === 'DZF' || orderInfo.orderStatus === 'DSH'))
              "
            >
              <div class="uploader-btn">
                <el-button type="primary">选择文件</el-button>
                <p class="tips" v-if="customer === 'sellder' && orderInfo.orderStatus === 'DFH'">提示：请上传磅单、装车图、送货单</p>
                <p class="tips">可上传格式：JPG，PNG，PDF，ZIP压缩文件</p>
              </div>
            </el-upload>
          </div>
        </div>
        <div class="input-box" v-if="customer == 'sellder' && orderInfo.orderStatus == 'DFH' && (orderInfo.orderSn.indexOf('TS') != -1 || orderInfo.orderSn.indexOf('AU') != -1)">
          <div class="input-item">
            <p class="tit"><span class="required">*</span>物流公司</p>
            <el-input v-model="param.shipChannel" placeholder="请输入"></el-input>
          </div>
          <div class="input-item">
            <p class="tit"><span class="required">*</span>物流号/车牌号</p>
            <el-input v-model="param.shipSn" placeholder="请输入"></el-input>
          </div>
          <div class="btn-box">
            <p class="tit"><span class="required">*</span><span>上传发票：</span></p>
            <div class="uplaod-box">
              <el-upload
                class="chen-image-uploader"
                :action="uploadPath"
                :on-preview="handlePreview"
                :on-remove="handleRemove2"
                :before-remove="beforeRemove"
                :on-success="handleSuccess2"
                :before-upload="beforeUpload"
                multiple
                :on-exceed="handleExceed"
                :file-list="fileList2"
                submit
                v-if="
                  (customer === 'sellder' && (orderInfo.orderStatus === 'DFH' || orderInfo.orderStatus === 'DKP')) ||
                    (customer === 'buyder' && (orderInfo.orderStatus === 'DZF' || orderInfo.orderStatus === 'DSH'))
                "
              >
                <div class="uploader-btn">
                  <el-button type="primary">选择文件</el-button>
                  <p class="tips">可上传格式：JPG，PNG，PDF，ZIP压缩文件</p>
                </div>
              </el-upload>
            </div>
          </div>

          <div class="input-item">
            <p class="tit"><span class="required">*</span>发票代码</p>
            <el-input v-model="param.invoiceSn" placeholder="请输入"></el-input>
          </div>
          <div class="input-item">
            <p class="tit"><span class="required">*</span>发票号码</p>
            <el-input v-model="param.invoiceNo" placeholder="请输入"></el-input>
          </div>
        </div>

        <div class="input-box" v-if="customer == 'sellder' && orderInfo.orderStatus == 'DKP'">
          <div class="input-item">
            <p class="tit"><span class="required">*</span>发票代码</p>
            <el-input v-model="param.invoiceSn" placeholder="请输入"></el-input>
          </div>
          <div class="input-item">
            <p class="tit"><span class="required">*</span>发票号码</p>
            <el-input v-model="param.invoiceNo" placeholder="请输入"></el-input>
          </div>
        </div>

        <div class="footer">
          <!--<el-button type="primary" class="btn" @click="submitOpr" v-if="customer == 'buyder' && (orderInfo.orderStatus ==='DFH')">取消订单</el-button>-->
          <el-button type="primary" class="btn" @click="submitOpr" v-if="customer == 'buyder' && orderInfo.orderStatus === 'DSH'">收货</el-button>
          <el-button type="primary" class="btn" @click="submitOpr" v-if="customer == 'sellder' && (orderInfo.orderStatus === 'DFH' || orderInfo.orderStatus === 'DKP')">提交</el-button>
          <el-button type="primary" class="btn" @click="submitOpr" v-if="customer == 'buyder' && orderInfo.orderStatus === 'DZF'">提交</el-button>
          <el-button type="primary" class="btn" @click="submitOpr" v-if="customer == 'sellder' && orderInfo.orderStatus === 'ZFC'">确认收款</el-button>
          <!--<el-button type="primary" class="btn" @click="submitOpr" v-if="customer == 'sellder' && orderInfo.orderStatus === 'QXC'">确认取消</el-button>-->
        </div>
      </div>
    </div>

    <div>
      <h2 style="margin-bottom:10px">操作记录</h2>
      <div style="background:#fff;padding:10px">
        <el-table :data="operatingRecordList" :header-cell-style="{background: '#F5f5f5', height: ' 22px', 'line-height': '22px', color: '#999'}" style="width: 100%">
          <el-table-column type="index" label="序号" width="100"> </el-table-column>
          <el-table-column prop="status" label="状态" width="150">
            <template slot-scope="scope">
              {{ scope.row.status | orderRecordStatus }}
            </template>
          </el-table-column>
          <el-table-column prop="clientName" label="企业名称"> </el-table-column>
          <el-table-column prop="dealUser" label="处理人" width="150"> </el-table-column>
          <el-table-column prop="dealTime" label="处理时间" width="240"> </el-table-column>
          <el-table-column label="文件" fixed="right" width="180">
            <template slot-scope="scope">
              <div class="operation-file">
                <!--如果不是是支付，发货，开票，收货则不会有多个图片-->
                <a
                  v-if="scope.row.status != 'ZF' && scope.row.status != 'FH' && scope.row.status != 'KP' && scope.row.status != 'SH' && scope.row.fileName && scope.row.fileName != 'null'"
                  :href="scope.row.fileUrl"
                  :title="scope.row.fileName"
                  target="_blank"
                  >{{ scope.row.fileName }}</a
                >
                <!--如果是支付，发货，开票，收货 ，则可能有多个图片显示-->
                <a
                  v-if="
                    (scope.row.status == 'ZF' || scope.row.status == 'FH' || scope.row.status == 'KP' || scope.row.status == 'SH') &&
                      scope.row.fileName &&
                      scope.row.fileUrl &&
                      scope.row.fileName.indexOf('[') != -1
                  "
                  v-for="(item, i) in 20"
                  :href="JSON.parse(scope.row.fileUrl)[i]"
                  :title="JSON.parse(scope.row.fileName)[i]"
                  target="_blank"
                  ><p>{{ JSON.parse(scope.row.fileName)[i] }}</p></a
                >
                <!--兼容之前的订单操作消息-->
                <a
                  v-if="
                    (scope.row.status == 'ZF' || scope.row.status == 'FH' || scope.row.status == 'KP' || scope.row.status == 'SH') &&
                      scope.row.fileName &&
                      scope.row.fileUrl &&
                      scope.row.fileName.indexOf('[') == -1
                  "
                  :href="scope.row.fileUrl"
                  :title="scope.row.fileName"
                  target="_blank"
                  >{{ scope.row.fileName }}</a
                >

                <a v-if="scope.row.status == 'FH' && invoiceData && invoiceData.shipChannel" href="$" onclick="return false;"
                  ><p>物流公司：{{ invoiceData.shipChannel }}</p></a
                >
                <a v-if="scope.row.status == 'FH' && invoiceData && invoiceData.shipSn" href="$" onclick="return false;"
                  ><p>物流号/车牌号：{{ invoiceData.shipSn }}</p></a
                >
                <a v-if="scope.row.status == 'KP' && invoiceData && invoiceData.invoiceSn" href="$" onclick="return false;"
                  ><p>发票代码：{{ invoiceData.invoiceSn }}</p></a
                >
                <a v-if="scope.row.status == 'KP' && invoiceData && invoiceData.invoiceNo" href="$" onclick="return false;"
                  ><p>发票号码：{{ invoiceData.invoiceNo }}</p></a
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import {detailOrder, getOperatingRecord, changeOrderStatus, getInvoiceInfo} from '../api';
import {uploadPath} from '../../User/api';
export default {
  data() {
    return {
      customer: 'buyder',
      pagesName: '',
      isHandleShow: false,
      contractId: '', //合同id
      orderStatus: '',
      orderId: undefined,
      uploadPath,
      fileList: [],
      fileList2: [],
      fileList3: [],
      fileList4: [],
      fileList5: [],
      fileSize: '',
      param: {
        orderId: this.$route.query.orderid,
        status: '',
        fileUrl: '',
        fileName: '',
        fileNames: [],
        fileUrls: [],
        fileNames2: [],
        fileUrls2: [],
        shipSn: '',
        shipChannel: '',
        payId: '',
        invoiceSn: '',
        invoiceNo: '',
        fileUrl2: '',
        fileName2: '',
        fileNames3: [],
        fileUrls3: [],
        fileNames4: [],
        fileUrls4: [],
        fileNames5: [],
        fileUrls5: []
      },
      buyer: {},
      orderInfo: {},
      brands: {},
      tableData: [],
      operatingRecordList: [
        {
          clientName: '',
          dealTime: '',
          dealUser: '',
          fileName: [],
          fileUrl: [],
          id: '',
          status: ''
        }
      ],
      imageUrl: '',
      imageName: '',
      logisticsCompany: '',
      logisticsNum: '',
      orderStatusText: '',
      flowStep: 0,
      invoiceData: {
        shipChannel: '',
        shipSn: '',
        invoiceSn: '',
        invoiceNo: ''
      },
      invoiceInfo: {}
    };
  },
  filters: {
    orderRecordStatus(value) {
      switch (value) {
        case 'NEW':
          return '创建订单';
          break;
        case 'FH':
          return '发货';
          break;
        case 'SH':
          return '签收';
          break;
        case 'KP':
          return '开发票';
          break;
        case 'ZF':
          return '付款(商票)';
          break;
        case 'SK':
          return '收款';
          break;
        case 'QX':
          return '取消订单';
          break;
        case 'QXS':
          return '取消成功';
          break;
        case 'QXR':
          return '拒绝取消';
          break;
        case 'YQS':
          return '合同签署';
          break;
        case 'XQ':
          return '详情';
          break;
      }
    }
  },
  methods: {
    handleRemove(file, fileList) {
      this.param.fileNames = [];
      this.param.fileUrls = [];
      for (let i = 0; i < fileList.length; i++) {
        this.param.fileNames.push(fileList[i].name);
        this.param.fileUrls.push(fileList[i].response.data.url);
      }
      console.log(this.param.fileNames);
      console.log(this.param.fileUrls);
    },
    handleRemove2(file, fileList) {
      this.param.fileNames2 = [];
      this.param.fileUrls2 = [];
      for (let i = 0; i < fileList.length; i++) {
        this.param.fileNames2.push(fileList[i].name);
        this.param.fileUrls2.push(fileList[i].response.data.url);
      }
      console.log(this.param.fileNames2);
      console.log(this.param.fileUrls2);
    },
    handleRemove3(file, fileList) {
      this.param.fileNames3 = [];
      this.param.fileUrls3 = [];
      for (let i = 0; i < fileList.length; i++) {
        this.param.fileNames3.push(fileList[i].name);
        this.param.fileUrls3.push(fileList[i].response.data.url);
      }
      console.log(this.param.fileNames3);
      console.log(this.param.fileUrls3);
    },
    handleRemove4(file, fileList) {
      this.param.fileNames4 = [];
      this.param.fileUrls4 = [];
      for (let i = 0; i < fileList.length; i++) {
        this.param.fileNames4.push(fileList[i].name);
        this.param.fileUrls4.push(fileList[i].response.data.url);
      }
      console.log(this.param.fileNames4);
      console.log(this.param.fileUrls4);
    },
    handleRemove5(file, fileList) {
      this.param.fileNames5 = [];
      this.param.fileUrls5 = [];
      for (let i = 0; i < fileList.length; i++) {
        this.param.fileNames5.push(fileList[i].name);
        this.param.fileUrls5.push(fileList[i].response.data.url);
      }
      console.log(this.param.fileNames5);
      console.log(this.param.fileUrls5);
    },

    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`只能上传 5 个文件`);
    },
    beforeRemove(file, fileList) {
      return this.$confirm(` 确定移除 ${file.name}？`);
    },
    detail() {
      detailOrder({orderId: this.orderId, customer: this.customer}).then(res => {
        this.contractId = res.data.contractInfo.id;
        this.orderStatus = res.data.orderInfo.orderStatus;

        this.tableData = res.data.orderGoodsVoLists;
        this.orderInfo = res.data.orderInfo;
        this.orderInfo.clientInfoName = res.data.clientInfoName;
        this.brands = res.data.brandCartgoodsList;
        this.orderStatusText = res.data.orderStatusText;
        if (res.data.orderInfo) {
          ['DFWQ', 'DQS'].indexOf(res.data.orderInfo.orderStatus) >= 0 && (this.flowStep = 1);
          ['DZF'].indexOf(res.data.orderInfo.orderStatus) >= 0 && (this.flowStep = 2);
          ['DFH', 'DKP'].indexOf(res.data.orderInfo.orderStatus) >= 0 && (this.flowStep = 3);
          ['DSH'].indexOf(res.data.orderInfo.orderStatus) >= 0 && (this.flowStep = 4);
          ['END'].indexOf(res.data.orderInfo.orderStatus) >= 0 && (this.flowStep = 5);
        }
        this.getInvoiceInfo(res.data.orderInfo.userId);
      });
    },
    goHome() {
      this.$router.push({path: '/home/HomePage'});
    },
    printBtn() {},
    getOperatingRecord: function() {
      getOperatingRecord({
        id: this.orderId,
        orderType: 1
      }).then(res => {
        var str = res.data.splice(0, 1);
        if (str != null && str[0].fileName != null) {
          this.invoiceData = JSON.parse(str[0].fileName);
        }
        //  res.data.splice[]
        this.operatingRecordList = res.data || [];
        //
        // var a= "[\"1.png\",\"0507113615.png\"]"
        //
        // console.log(JSON.parse(a))
        // console.log(a)
        // console.log(JSON.parse(a)[0])
        // for (let i=0;i< this.operatingRecordList.length){
        //     if (this.operatingRecordList.fileName!=null && )
        // }
      });
    },
    // 图片上传之前
    beforeUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/pdf' || file.type == 'application/pdf';
      const isLt10M = file.size / 1024 / 1024 < 10;

      const isZip = file.type === 'application/x-zip-compressed';
      const zipLimit30M = file.size / 1024 / 1024 < 30;

      if (!(isJPG || isZip)) {
        this.$message.error('上传文件只能是 JPG/PNG/PDF/ZIP压缩 格式!');
        return false;
      }
      if (isJPG && !isLt10M) {
        this.$message.error('上传文件大小不能超过 10MB!');
        return false;
      }

      if (isZip && !zipLimit30M) {
        this.$message.error('上传文件大小不能超过 30MB!');
        return false;
      }
      return true;
    },
    //   图片上传成功
    handleSuccess(res, file, fileList) {
      this.param.fileNames = [];
      this.param.fileUrls = [];
      if (res.errno == 0) {
        for (let i = 0; i < fileList.length; i++) {
          this.param.fileNames.push(fileList[i].name);
          this.param.fileUrls.push(fileList[i].response.data.url);
        }
        console.log(this.param.fileNames);
        console.log(this.param.fileUrls);
        //     alert(JSON.stringify( this.param.fileList ))
        this.$notify.success({
          title: '成功',
          message: '上传成功'
        });
      }
    },
    handleSuccess2(res, file, fileList) {
      this.param.fileNames2 = [];
      this.param.fileUrls2 = [];
      if (res.errno == 0) {
        for (let i = 0; i < fileList.length; i++) {
          this.param.fileNames2.push(fileList[i].name);
          this.param.fileUrls2.push(fileList[i].response.data.url);
        }
        console.log(this.param.fileNames2);
        console.log(this.param.fileUrls2);
        this.$notify.success({
          title: '成功',
          message: '上传成功'
        });
      }
    },
    handleSuccess3(res, file, fileList) {
      this.param.fileNames3 = [];
      this.param.fileUrls3 = [];
      if (res.errno == 0) {
        for (let i = 0; i < fileList.length; i++) {
          this.param.fileNames3.push(fileList[i].name);
          this.param.fileUrls3.push(fileList[i].response.data.url);
        }
        console.log(this.param.fileNames3);
        console.log(this.param.fileUrls3);
        this.$notify.success({
          title: '成功',
          message: '上传成功'
        });
      }
    },
    handleSuccess4(res, file, fileList) {
      this.param.fileNames4 = [];
      this.param.fileUrls4 = [];
      if (res.errno == 0) {
        for (let i = 0; i < fileList.length; i++) {
          this.param.fileNames4.push(fileList[i].name);
          this.param.fileUrls4.push(fileList[i].response.data.url);
        }
        console.log(this.param.fileNames4);
        console.log(this.param.fileUrls4);
        this.$notify.success({
          title: '成功',
          message: '上传成功'
        });
      }
    },
    handleSuccess5(res, file, fileList) {
      this.param.fileNames5 = [];
      this.param.fileUrls5 = [];
      if (res.errno == 0) {
        for (let i = 0; i < fileList.length; i++) {
          this.param.fileNames5.push(fileList[i].name);
          this.param.fileUrls5.push(fileList[i].response.data.url);
        }
        console.log(this.param.fileNames5);
        console.log(this.param.fileUrls5);
        this.$notify.success({
          title: '成功',
          message: '上传成功'
        });
      }
    },

    // 删除上传图片
    delImage() {
      this.param.fileName = '';
      this.param.fileUrl = '';
    },
    delImage2() {
      this.param.fileName2 = '';
      this.param.fileUrl2 = '';
    },
    // 提交图片
    submitImage() {
      console.log('提交图片：' + this.imageUrl);
    },
    // 提交交易操作
    submitOpr() {
      //操作状态
      this.param.status = this.getOprtStatus();
      if (this.param.status === 'FH' || this.param.status === 'KP' || this.param.status === 'ZF' || this.param.status === 'SH') {
        if (this.param.fileUrls.length == 0) {
          this.$message.error('请上传凭证');
          return;
        }
        if (this.param.status === 'FH') {
          if (this.param.shipSn === '' || this.param.shipChannel === '') {
            this.$message.error('请输入物流信息');
            return;
          }
          if (this.param.fileUrls2.length == 0) {
            this.$message.error('请上传发票');
            return;
          }
          if (this.invoiceInfo.payTaxesNo) {
            if (this.param.invoiceSn === '' || this.param.invoiceNo === '') {
              this.$message.error('请输入发票信息');
              return;
            }
          }

          //     if (this.param.fileUrls3.length==0){
          //         this.$message.error('请上传磅单')
          //         return
          //     }else {
          //         for (let i=0;i<this.param.fileUrls3.length;i++){
          //             this.param.fileUrls.push(this.param.fileUrls3[i]);
          //             this.param.fileNames.push(this.param.fileNames3[i]);
          //         }
          //         this.param.fileUrls3=undefined;
          //         this.param.fileNames3=undefined;
          //
          //     }
          //     if (this.param.fileUrls4.length==0){
          //         this.$message.error('请上传装车图')
          //         return
          //     }else {
          //         for (let i=0;i<this.param.fileUrls4.length;i++){
          //             this.param.fileUrls.push(this.param.fileUrls4[i]);
          //             this.param.fileNames.push(this.param.fileNames4[i]);
          //         }
          //         this.param.fileUrls4=undefined;
          //         this.param.fileNames4=undefined;
          //     }
          //     if (this.param.fileUrls5.length==0){
          //         this.$message.error('请上传送货单')
          //         return
          //     }else {
          //         for (let i=0;i<this.param.fileUrls5.length;i++){
          //             this.param.fileUrls.push(this.param.fileUrls5[i]);
          //             this.param.fileNames.push(this.param.fileNames5[i]);
          //         }
          //         this.param.fileUrls5=undefined;
          //         this.param.fileNames5=undefined;
          //     }
          //
        }
      }

      (this.param.orderSn = this.orderInfo.orderSn),
        (this.param.sellUser = this.orderInfo.consignee),
        (this.param.sellTel = this.orderInfo.mobile),
        (this.param.sellAddress = this.orderInfo.address),
        (this.param.invoiceAmt = this.orderInfo.orderPrice),
        (this.param.buyUser = this.brands[0].userName),
        (this.param.buyTel = this.brands[0].phone),
        (this.param.buyAddress = this.brands[0].address),
        changeOrderStatus(this.param).then(res => {
          if (res.errno === 0) {
            this.$notify.success({
              title: '成功',
              message: '提交成功'
            });
            this.param.fileUrl = '';
            this.param.fileName = '';
            this.detail();
            this.getOperatingRecord();
          }
        });
    },
    //合同签署
    toContractDetail() {
      this.$router.push({
        path: '/User/ContractDetail',
        query: {
          id: null,
          tciId: this.contractId,
          orderNum: this.orderInfo.orderSn,
          customer: this.$route.query.customer
        }
      });
    },
    getOprtStatus() {
      if (this.orderInfo.orderStatus === 'DFH') {
        if (this.customer === 'sellder') {
          return 'FH';
        } else if (this.customer === 'buyder') {
          return 'QX';
        }
      } else if (this.orderInfo.orderStatus === 'DSH') {
        if (this.customer === 'buyder') {
          return 'SH';
        }
      } else if (this.orderInfo.orderStatus === 'DKP') {
        if (this.customer === 'sellder') {
          return 'KP';
        }
      } else if (this.orderInfo.orderStatus === 'DZF') {
        if (this.customer === 'buyder') {
          return 'ZF';
        }
      } else if (this.orderInfo.orderStatus === 'ZFC') {
        if (this.customer === 'sellder') {
          return 'SK';
        }
      } else if (this.orderInfo.orderStatus === 'QXC') {
        if (this.customer === 'sellder') {
          return 'QXS';
        }
      }
    },
    formatData(row) {
      let rowPerson = (row && row.specifications) || [];
      let arr = [];
      rowPerson.forEach(item => {
        //如果数据特别多的话,还可以在这里加if判断,仅展示规定的数据
        arr.push(item);
      });
      if (arr.length == 0) {
        return '无';
      }
      return arr.join(', ');
    },
    formatWeight(row) {
      if (row.weight == null || row.weight == 0) {
        return '-';
      } else {
        return row.weight;
      }
    },
    getInvoiceInfo(id) {
      getInvoiceInfo({
        userid: id
      }).then(res => {
        if (res.errno === 0) {
          this.invoiceInfo = res.data || {};
        }
      });
    }
  },
  created() {
    this.orderId = this.$route.query.orderid;
    this.customer = this.$route.query.customer;
    this.pagesName = this.$route.name;
    if (this.$route.query.isHandleShow != undefined) {
      this.isHandleShow = this.$route.query.isHandleShow;
    }
    this.detail();
    this.getOperatingRecord();
  }
};
</script>

<style lang="less" scoped>
/deep/.table-header {
  .is-leaf {
    background: #e8e8e8;
    color: #666;
    text-align: center;
  }
}
/deep/.cell {
  text-align: center;
}
.operate {
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    padding: 20px;

    .btn-box {
      padding: 40px;
      display: flex;

      .tit {
        padding-top: 10px;
        text-align: right;

        .required {
          color: #fe1846;
        }
        .image-name {
          color: #3a8ee6;
          text-decoration: underline;
        }
      }
      .reupload {
        padding-top: 10px;
        margin-left: 30px;
      }
      .uploader-btn {
        text-align: left;
        margin-left: 8px;

        .tips {
          font-size: 12px;
          color: #999;
          margin-top: 8px;
        }
      }
      .del {
        padding-top: 10px;
        margin-left: 18px;
        cursor: pointer;
      }
    }
    .input-box {
      margin-bottom: 30px;

      .input-item {
        display: flex;
        align-items: center;
        margin-bottom: 14px;

        .tit {
          width: 122px;
          text-align: right;
          padding-right: 10px;

          .required {
            color: #fe1846;
          }
        }
      }
    }
    .footer {
      width: 100%;
      padding-top: 20px;
      box-sizing: border-box;
      // border-top: 1px dashed #ccc;
      text-align: center;

      .btn {
        width: 150px;
      }
    }
  }
}
.order-status {
  display: flex;
  // margin-top: 10px;
  background: #fff;
  height: 220px;
  padding: 20px;

  &-box {
    flex: 1;
    background: #f3ffff;
    padding: 20px;

    .box-item {
      display: flex;

      .title {
        text-align: right;
        color: #999;
        margin-bottom: 20px;
      }
    }
  }
}
.invoice-box {
  .invoice-content {
    padding: 20px;
    background: #fff;

    .invoice-item {
      padding: 5px 0;
      font-size: 14px;

      .title {
        display: inline-block;
        width: 90px;
        text-align: right;
      }
    }
  }
}

/deep/.is-leaf {
  font-weight: 400;
}

.border {
  //   border-bottom: 1px solid #ebeef5;
}

.uplaod-box {
  width: 200px;
}
</style>
