var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { width: "1200px", margin: "0 auto", padding: "20px" } },
    [
      _c("div"),
      _c("div", { staticClass: "order-status" }, [
        _c("div", { staticClass: "order-status-box" }, [
          _vm._m(0),
          _c("div", { staticClass: "box-item" }, [
            _c("div", { staticClass: "title" }, [_vm._v(" 订单号： ")]),
            _c("div", { staticClass: "text" }, [
              _vm._v(_vm._s(_vm.orderInfo.orderSn)),
            ]),
          ]),
          _c("div", { staticClass: "box-item" }, [
            _c("div", { staticClass: "title" }, [_vm._v(" 订单状态： ")]),
            _c("div", { staticClass: "text" }, [
              _vm._v(_vm._s(_vm.orderStatusText)),
            ]),
          ]),
          _c("div", { staticClass: "box-item" }, [
            _c("div", { staticClass: "title" }, [_vm._v(" 订单金额： ")]),
            _c(
              "div",
              { staticClass: "text", staticStyle: { color: "#FF0033" } },
              [_vm._v(_vm._s(_vm.orderInfo.orderPrice))]
            ),
            _vm._v(" 元 "),
          ]),
          _vm.orderStatus == "DQS"
            ? _c(
                "p",
                {
                  staticStyle: {
                    padding: "10px 20px",
                    color: "red",
                    cursor: "pointer",
                  },
                  on: { click: _vm.toContractDetail },
                },
                [_vm._v("订单提交成功，请及时完成合同签署")]
              )
            : _vm._e(),
        ]),
        _c(
          "div",
          {
            staticClass: "order-status-box",
            staticStyle: { "padding-top": "66.67px" },
          },
          [
            _vm.orderInfo.shipChannel
              ? _c("div", { staticClass: "box-item" }, [
                  _c("div", { staticClass: "title" }, [_vm._v(" 物流公司： ")]),
                  _c("div", { staticClass: "text" }, [
                    _vm._v(_vm._s(_vm.orderInfo.shipChannel)),
                  ]),
                ])
              : _vm._e(),
            _vm.orderInfo.shipSn
              ? _c("div", { staticClass: "box-item" }, [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(" 物流号/车牌号： "),
                  ]),
                  _c("div", { staticClass: "text" }, [
                    _vm._v(_vm._s(_vm.orderInfo.shipSn)),
                  ]),
                ])
              : _vm._e(),
          ]
        ),
      ]),
      _c("div", [
        _c("h1", [_vm._v("订单流程")]),
        ["QXC", "QXS"].indexOf(_vm.orderInfo.orderStatus) < 0
          ? _c(
              "div",
              {
                staticStyle: { background: "#fff", padding: "20px 20px 10px" },
              },
              [
                _c(
                  "el-steps",
                  { attrs: { active: _vm.flowStep, "align-center": "" } },
                  [
                    _c("el-step", { attrs: { title: "确认订单" } }),
                    _c("el-step", { attrs: { title: "合同签署" } }),
                    _c("el-step", { attrs: { title: "买家支付" } }),
                    _c("el-step", {
                      attrs: { title: "供应商确认收款、发货、开发票" },
                    }),
                    _c("el-step", { attrs: { title: "买家签收" } }),
                  ],
                  1
                ),
              ],
              1
            )
          : _c(
              "div",
              {
                staticStyle: {
                  padding: "24px",
                  background: "#fff",
                  color: "#333333",
                  "font-size": "14px",
                  "line-height": "24px",
                },
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.orderInfo.orderStatu === "QXC"
                        ? "订单取消待确认"
                        : "订单已取消成功"
                    ) +
                    " "
                ),
              ]
            ),
      ]),
      _c("div", [
        _c("h1", [_vm._v("订单信息")]),
        _c(
          "div",
          { staticStyle: { background: "#fff", padding: "10px 20px 40px" } },
          [
            _c("h3", [
              _vm._v(
                _vm._s(_vm.customer === "seller" ? "卖方" : "供应商") + "信息："
              ),
            ]),
            _vm._l(_vm.brands, function (item, index) {
              return _c("div", { key: index }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "margin-bottom": "10px",
                      "margin-top": "10px",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          flex: "0 0 80px",
                          "text-align": "right",
                          color: "#999",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.customer === "seller" ? "卖方" : "供应商"
                          ) + "："
                        ),
                      ]
                    ),
                    _c("div", { staticStyle: { flex: "0 0 500px" } }, [
                      _vm._v(_vm._s(item.brandName)),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { staticStyle: { display: "flex", "margin-bottom": "10px" } },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          flex: "0 0 80px",
                          "text-align": "right",
                          color: "#999",
                        },
                      },
                      [_vm._v("联系人：")]
                    ),
                    _c("div", [_vm._v(_vm._s(item.userName))]),
                  ]
                ),
                _c(
                  "div",
                  { staticStyle: { display: "flex", "margin-bottom": "10px" } },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          flex: "0 0 80px",
                          "text-align": "right",
                          color: "#999",
                        },
                      },
                      [_vm._v("电话：")]
                    ),
                    _c("div", [_vm._v(_vm._s(item.phone))]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "border",
                    staticStyle: { display: "flex", "padding-bottom": "30px" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          flex: "0 0 80px",
                          "text-align": "right",
                          color: "#999",
                        },
                      },
                      [_vm._v("地址：")]
                    ),
                    _c("div", { staticStyle: { flex: "0 0 500px" } }, [
                      _vm._v(" " + _vm._s(item.address) + " "),
                    ]),
                  ]
                ),
                _c("div", { staticClass: "dash" }),
              ])
            }),
            _c("h3", [_vm._v("买方信息：")]),
            _c("div", [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "margin-bottom": "10px",
                    "margin-top": "10px",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        flex: "0 0 80px",
                        "text-align": "right",
                        color: "#999",
                      },
                    },
                    [_vm._v("买方：")]
                  ),
                  _c("div", { staticStyle: { flex: "0 0 500px" } }, [
                    _vm._v(_vm._s(_vm.orderInfo.clientInfoName)),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "margin-bottom": "10px",
                    "align-items": "center",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        flex: "0 0 80px",
                        "text-align": "right",
                        color: "#999",
                      },
                    },
                    [_vm._v("联系人：")]
                  ),
                  _c("div", { staticStyle: { flex: "0 0 500px" } }, [
                    _vm._v(_vm._s(_vm.orderInfo.consignee)),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "margin-bottom": "10px",
                    "align-items": "center",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        flex: "0 0 80px",
                        "text-align": "right",
                        color: "#999",
                      },
                    },
                    [_vm._v("电话：")]
                  ),
                  _c("div", { staticStyle: { flex: "0 0 500px" } }, [
                    _vm._v(_vm._s(_vm.orderInfo.mobile)),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "border",
                  staticStyle: {
                    display: "flex",
                    "padding-bottom": "30px",
                    "align-items": "center",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        flex: "0 0 80px",
                        "text-align": "right",
                        color: "#999",
                      },
                    },
                    [_vm._v("地址：")]
                  ),
                  _c("div", { staticStyle: { flex: "0 0 500px" } }, [
                    _vm._v(_vm._s(_vm.orderInfo.address)),
                  ]),
                ]
              ),
            ]),
            _c("h3", [_vm._v("商品信息")]),
            _c(
              "div",
              {
                staticClass: "border",
                staticStyle: { position: "relative", "padding-bottom": "90px" },
              },
              [
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.tableData,
                      border: "",
                      fit: "",
                      "header-cell-style": {
                        background: "#F5f5f5",
                        height: "22px",
                        "line-height": "22px",
                        color: "#999",
                      },
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "goodsSn",
                        label: "货物",
                        "min-width": "95",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "market",
                        label: "所在仓库",
                        "min-width": "180",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "goodsName",
                        label: "品名",
                        "min-width": "100",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "specifications",
                        label: "规格",
                        "min-width": "120",
                        formatter: _vm.formatData,
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "stuff",
                        label: "材质",
                        "min-width": "95",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "manufacturer",
                        label: "钢厂",
                        "min-width": "95",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "number",
                        label: "数量",
                        "min-width": "85",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "weight",
                        label: "重量(吨)",
                        "min-width": "95",
                        formatter: _vm.formatWeight,
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "processCharge",
                        label: "加工费",
                        "min-width": "95",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "price",
                        label: "单价(元)",
                        "min-width": "120",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "totalPrice",
                        label: "金额(元)",
                        "min-width": "120",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      position: "absolute",
                      right: "0px",
                      bottom: "20px",
                      "font-size": "14px",
                    },
                  },
                  [
                    _c("span", [_vm._v("合计：")]),
                    _c("span", { staticStyle: { "margin-left": "10px" } }, [
                      _vm._v(_vm._s(_vm.orderInfo.orderPrice) + "元"),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "dash" }),
            _c(
              "div",
              { staticStyle: { position: "relative", height: "40px" } },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      position: "absolute",
                      right: "0",
                      top: "20px",
                      "font-size": "18px",
                    },
                  },
                  [
                    _c("span", [_vm._v("应付总计：")]),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          color: "#FC4500",
                          "font-size": "18px",
                          "margin-left": "10px",
                        },
                      },
                      [_vm._v(_vm._s(_vm.orderInfo.orderPrice))]
                    ),
                    _vm._v(" 元 "),
                  ]
                ),
              ]
            ),
          ],
          2
        ),
      ]),
      _c(
        "div",
        { staticStyle: { padding: "0 20px 20px", background: "#fff" } },
        [
          _c(
            "div",
            { staticStyle: { margin: "0 auto", width: "390px" } },
            [
              _vm.pagesName == "MyOrderInfo"
                ? _c(
                    "router-link",
                    {
                      attrs: { to: "/User/MyOrder?customer=" + this.customer },
                    },
                    [
                      _c(
                        "el-button",
                        { staticStyle: { "margin-right": "20px" } },
                        [_vm._v("返回订单列表")]
                      ),
                    ],
                    1
                  )
                : _c(
                    "el-button",
                    {
                      staticStyle: { "margin-right": "20px" },
                      on: { click: _vm.goHome },
                    },
                    [_vm._v("返回首页")]
                  ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.printBtn } },
                [_vm._v("打印")]
              ),
              _vm.orderStatus == "DQS"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.toContractDetail },
                    },
                    [_vm._v("合同签署")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm.isHandleShow &&
      _vm.customer == "sellder" &&
      ((_vm.orderInfo.orderStatus == "DFH" &&
        (_vm.orderInfo.orderSn.indexOf("TS") != -1 ||
          _vm.orderInfo.orderSn.indexOf("AU") != -1)) ||
        _vm.orderInfo.orderStatus == "DKP")
        ? _c("div", { staticClass: "invoice-box" }, [
            _c("h2", [_vm._v("开票信息")]),
            _c("div", { staticClass: "invoice-content" }, [
              _c("div", { staticClass: "invoice-item" }, [
                _c("span", { staticClass: "title" }, [_vm._v("单位名称：")]),
                _vm._v(
                  " " + _vm._s(_vm.orderInfo.clientInfoName || "--") + " "
                ),
              ]),
              _c("div", { staticClass: "invoice-item" }, [
                _c("span", { staticClass: "title" }, [_vm._v("开户行：")]),
                _vm._v(" " + _vm._s(_vm.invoiceInfo.accountBank || "--") + " "),
              ]),
              _c("div", { staticClass: "invoice-item" }, [
                _c("span", { staticClass: "title" }, [_vm._v("开户行账号：")]),
                _vm._v(" " + _vm._s(_vm.invoiceInfo.accountNo || "--") + " "),
              ]),
              _c("div", { staticClass: "invoice-item" }, [
                _c("span", { staticClass: "title" }, [_vm._v("纳税号：")]),
                _vm._v(" " + _vm._s(_vm.invoiceInfo.payTaxesNo || "--") + " "),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.isHandleShow
        ? _c("div", { staticClass: "operate" }, [
            _c("h2", { staticStyle: { "margin-bottom": "10px" } }, [
              _vm._v("操作"),
            ]),
            _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "btn-box" }, [
                _vm.customer == "buyder" && _vm.orderInfo.orderStatus == "DZF"
                  ? _c("p", { staticClass: "tit" }, [
                      _c("span", { staticClass: "required" }, [_vm._v("*")]),
                      _vm._v("上传支付凭证："),
                      _vm.imageName
                        ? _c("span", { staticClass: "image-name" }, [
                            _vm._v(_vm._s(_vm.imageName)),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm.customer == "buyder" && _vm.orderInfo.orderStatus == "DSH"
                  ? _c("p", { staticClass: "tit" }, [
                      _c("span", { staticClass: "required" }, [_vm._v("*")]),
                      _vm._v("上传签收凭证："),
                      _vm.imageName
                        ? _c("span", { staticClass: "image-name" }, [
                            _vm._v(_vm._s(_vm.imageName)),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm.customer == "sellder" && _vm.orderInfo.orderStatus == "DFH"
                  ? _c("p", { staticClass: "tit" }, [
                      _c("span", { staticClass: "required" }, [_vm._v("*")]),
                      _vm._v("上传发货凭证："),
                      _vm.imageName
                        ? _c("span", { staticClass: "image-name" }, [
                            _vm._v(_vm._s(_vm.imageName)),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm.customer == "sellder" && _vm.orderInfo.orderStatus == "DKP"
                  ? _c("p", { staticClass: "tit" }, [
                      _c("span", { staticClass: "required" }, [_vm._v("*")]),
                      _vm._v("上传开票凭证："),
                      _vm.imageName
                        ? _c("span", { staticClass: "image-name" }, [
                            _vm._v(_vm._s(_vm.imageName)),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "uplaod-box" },
                  [
                    (_vm.customer === "sellder" &&
                      (_vm.orderInfo.orderStatus === "DFH" ||
                        _vm.orderInfo.orderStatus === "DKP")) ||
                    (_vm.customer === "buyder" &&
                      (_vm.orderInfo.orderStatus === "DZF" ||
                        _vm.orderInfo.orderStatus === "DSH"))
                      ? _c(
                          "el-upload",
                          {
                            staticClass: "upload-demo",
                            attrs: {
                              action: _vm.uploadPath,
                              "on-preview": _vm.handlePreview,
                              "on-remove": _vm.handleRemove,
                              "before-remove": _vm.beforeRemove,
                              "before-upload": _vm.beforeUpload,
                              "on-success": _vm.handleSuccess,
                              multiple: "",
                              "on-exceed": _vm.handleExceed,
                              "file-list": _vm.fileList,
                              submit: "",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "uploader-btn" },
                              [
                                _c(
                                  "el-button",
                                  { attrs: { type: "primary" } },
                                  [_vm._v("选择文件")]
                                ),
                                _vm.customer === "sellder" &&
                                _vm.orderInfo.orderStatus === "DFH"
                                  ? _c("p", { staticClass: "tips" }, [
                                      _vm._v(
                                        "提示：请上传磅单、装车图、送货单"
                                      ),
                                    ])
                                  : _vm._e(),
                                _c("p", { staticClass: "tips" }, [
                                  _vm._v(
                                    "可上传格式：JPG，PNG，PDF，ZIP压缩文件"
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _vm.customer == "sellder" &&
              _vm.orderInfo.orderStatus == "DFH" &&
              (_vm.orderInfo.orderSn.indexOf("TS") != -1 ||
                _vm.orderInfo.orderSn.indexOf("AU") != -1)
                ? _c("div", { staticClass: "input-box" }, [
                    _c(
                      "div",
                      { staticClass: "input-item" },
                      [
                        _vm._m(1),
                        _c("el-input", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.param.shipChannel,
                            callback: function ($$v) {
                              _vm.$set(_vm.param, "shipChannel", $$v)
                            },
                            expression: "param.shipChannel",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "input-item" },
                      [
                        _vm._m(2),
                        _c("el-input", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.param.shipSn,
                            callback: function ($$v) {
                              _vm.$set(_vm.param, "shipSn", $$v)
                            },
                            expression: "param.shipSn",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "btn-box" }, [
                      _vm._m(3),
                      _c(
                        "div",
                        { staticClass: "uplaod-box" },
                        [
                          (_vm.customer === "sellder" &&
                            (_vm.orderInfo.orderStatus === "DFH" ||
                              _vm.orderInfo.orderStatus === "DKP")) ||
                          (_vm.customer === "buyder" &&
                            (_vm.orderInfo.orderStatus === "DZF" ||
                              _vm.orderInfo.orderStatus === "DSH"))
                            ? _c(
                                "el-upload",
                                {
                                  staticClass: "chen-image-uploader",
                                  attrs: {
                                    action: _vm.uploadPath,
                                    "on-preview": _vm.handlePreview,
                                    "on-remove": _vm.handleRemove2,
                                    "before-remove": _vm.beforeRemove,
                                    "on-success": _vm.handleSuccess2,
                                    "before-upload": _vm.beforeUpload,
                                    multiple: "",
                                    "on-exceed": _vm.handleExceed,
                                    "file-list": _vm.fileList2,
                                    submit: "",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "uploader-btn" },
                                    [
                                      _c(
                                        "el-button",
                                        { attrs: { type: "primary" } },
                                        [_vm._v("选择文件")]
                                      ),
                                      _c("p", { staticClass: "tips" }, [
                                        _vm._v(
                                          "可上传格式：JPG，PNG，PDF，ZIP压缩文件"
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "input-item" },
                      [
                        _vm._m(4),
                        _c("el-input", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.param.invoiceSn,
                            callback: function ($$v) {
                              _vm.$set(_vm.param, "invoiceSn", $$v)
                            },
                            expression: "param.invoiceSn",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "input-item" },
                      [
                        _vm._m(5),
                        _c("el-input", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.param.invoiceNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.param, "invoiceNo", $$v)
                            },
                            expression: "param.invoiceNo",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.customer == "sellder" && _vm.orderInfo.orderStatus == "DKP"
                ? _c("div", { staticClass: "input-box" }, [
                    _c(
                      "div",
                      { staticClass: "input-item" },
                      [
                        _vm._m(6),
                        _c("el-input", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.param.invoiceSn,
                            callback: function ($$v) {
                              _vm.$set(_vm.param, "invoiceSn", $$v)
                            },
                            expression: "param.invoiceSn",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "input-item" },
                      [
                        _vm._m(7),
                        _c("el-input", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.param.invoiceNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.param, "invoiceNo", $$v)
                            },
                            expression: "param.invoiceNo",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "footer" },
                [
                  _vm.customer == "buyder" &&
                  _vm.orderInfo.orderStatus === "DSH"
                    ? _c(
                        "el-button",
                        {
                          staticClass: "btn",
                          attrs: { type: "primary" },
                          on: { click: _vm.submitOpr },
                        },
                        [_vm._v("收货")]
                      )
                    : _vm._e(),
                  _vm.customer == "sellder" &&
                  (_vm.orderInfo.orderStatus === "DFH" ||
                    _vm.orderInfo.orderStatus === "DKP")
                    ? _c(
                        "el-button",
                        {
                          staticClass: "btn",
                          attrs: { type: "primary" },
                          on: { click: _vm.submitOpr },
                        },
                        [_vm._v("提交")]
                      )
                    : _vm._e(),
                  _vm.customer == "buyder" &&
                  _vm.orderInfo.orderStatus === "DZF"
                    ? _c(
                        "el-button",
                        {
                          staticClass: "btn",
                          attrs: { type: "primary" },
                          on: { click: _vm.submitOpr },
                        },
                        [_vm._v("提交")]
                      )
                    : _vm._e(),
                  _vm.customer == "sellder" &&
                  _vm.orderInfo.orderStatus === "ZFC"
                    ? _c(
                        "el-button",
                        {
                          staticClass: "btn",
                          attrs: { type: "primary" },
                          on: { click: _vm.submitOpr },
                        },
                        [_vm._v("确认收款")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _c("div", [
        _c("h2", { staticStyle: { "margin-bottom": "10px" } }, [
          _vm._v("操作记录"),
        ]),
        _c(
          "div",
          { staticStyle: { background: "#fff", padding: "10px" } },
          [
            _c(
              "el-table",
              {
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.operatingRecordList,
                  "header-cell-style": {
                    background: "#F5f5f5",
                    height: " 22px",
                    "line-height": "22px",
                    color: "#999",
                  },
                },
              },
              [
                _c("el-table-column", {
                  attrs: { type: "index", label: "序号", width: "100" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "status", label: "状态", width: "150" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("orderRecordStatus")(scope.row.status)
                              ) +
                              " "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { prop: "clientName", label: "企业名称" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "dealUser", label: "处理人", width: "150" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "dealTime", label: "处理时间", width: "240" },
                }),
                _c("el-table-column", {
                  attrs: { label: "文件", fixed: "right", width: "180" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            { staticClass: "operation-file" },
                            [
                              scope.row.status != "ZF" &&
                              scope.row.status != "FH" &&
                              scope.row.status != "KP" &&
                              scope.row.status != "SH" &&
                              scope.row.fileName &&
                              scope.row.fileName != "null"
                                ? _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: scope.row.fileUrl,
                                        title: scope.row.fileName,
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v(_vm._s(scope.row.fileName))]
                                  )
                                : _vm._e(),
                              _vm._l(20, function (item, i) {
                                return (scope.row.status == "ZF" ||
                                  scope.row.status == "FH" ||
                                  scope.row.status == "KP" ||
                                  scope.row.status == "SH") &&
                                  scope.row.fileName &&
                                  scope.row.fileUrl &&
                                  scope.row.fileName.indexOf("[") != -1
                                  ? _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: JSON.parse(scope.row.fileUrl)[
                                            i
                                          ],
                                          title: JSON.parse(scope.row.fileName)[
                                            i
                                          ],
                                          target: "_blank",
                                        },
                                      },
                                      [
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(
                                              JSON.parse(scope.row.fileName)[i]
                                            )
                                          ),
                                        ]),
                                      ]
                                    )
                                  : _vm._e()
                              }),
                              (scope.row.status == "ZF" ||
                                scope.row.status == "FH" ||
                                scope.row.status == "KP" ||
                                scope.row.status == "SH") &&
                              scope.row.fileName &&
                              scope.row.fileUrl &&
                              scope.row.fileName.indexOf("[") == -1
                                ? _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: scope.row.fileUrl,
                                        title: scope.row.fileName,
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v(_vm._s(scope.row.fileName))]
                                  )
                                : _vm._e(),
                              scope.row.status == "FH" &&
                              _vm.invoiceData &&
                              _vm.invoiceData.shipChannel
                                ? _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: "$",
                                        onclick: "return false;",
                                      },
                                    },
                                    [
                                      _c("p", [
                                        _vm._v(
                                          "物流公司：" +
                                            _vm._s(_vm.invoiceData.shipChannel)
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              scope.row.status == "FH" &&
                              _vm.invoiceData &&
                              _vm.invoiceData.shipSn
                                ? _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: "$",
                                        onclick: "return false;",
                                      },
                                    },
                                    [
                                      _c("p", [
                                        _vm._v(
                                          "物流号/车牌号：" +
                                            _vm._s(_vm.invoiceData.shipSn)
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              scope.row.status == "KP" &&
                              _vm.invoiceData &&
                              _vm.invoiceData.invoiceSn
                                ? _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: "$",
                                        onclick: "return false;",
                                      },
                                    },
                                    [
                                      _c("p", [
                                        _vm._v(
                                          "发票代码：" +
                                            _vm._s(_vm.invoiceData.invoiceSn)
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              scope.row.status == "KP" &&
                              _vm.invoiceData &&
                              _vm.invoiceData.invoiceNo
                                ? _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: "$",
                                        onclick: "return false;",
                                      },
                                    },
                                    [
                                      _c("p", [
                                        _vm._v(
                                          "发票号码：" +
                                            _vm._s(_vm.invoiceData.invoiceNo)
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticStyle: {
          color: "#56C075",
          "font-size": "20px",
          "margin-bottom": "20px",
        },
      },
      [_c("i", { staticClass: "el-icon-success" }), _vm._v(" 订单提交成功 ")]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "tit" }, [
      _c("span", { staticClass: "required" }, [_vm._v("*")]),
      _vm._v("物流公司"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "tit" }, [
      _c("span", { staticClass: "required" }, [_vm._v("*")]),
      _vm._v("物流号/车牌号"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "tit" }, [
      _c("span", { staticClass: "required" }, [_vm._v("*")]),
      _c("span", [_vm._v("上传发票：")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "tit" }, [
      _c("span", { staticClass: "required" }, [_vm._v("*")]),
      _vm._v("发票代码"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "tit" }, [
      _c("span", { staticClass: "required" }, [_vm._v("*")]),
      _vm._v("发票号码"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "tit" }, [
      _c("span", { staticClass: "required" }, [_vm._v("*")]),
      _vm._v("发票代码"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "tit" }, [
      _c("span", { staticClass: "required" }, [_vm._v("*")]),
      _vm._v("发票号码"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }