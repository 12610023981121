import server from '@/api'

//商品列表
export function goodsList (param) {
  return server.goodsInfo.goodsList(param)
}

//品种
export function categoryAll (categoryId = null) {
  return server.category.categoryAll(categoryId);
}

//商品详情加企业信息
export function goodsDetailAndClient (params) {
  return server.goodsInfo.goodsDetailAndClient({ params })
}

//意向列表显示
export function cartList () {
  return server.cart.cartList({});
}
//删除意向
export function deleteCart (param) {
  return server.cart.deleteCart(param);
}
//增加意向
export function addCart (param) {
  return server.cart.addCart(param);
}

//获取购物车意向商品数量
export function getCartCount (params) {
  return server.cart.getCartCount({ params });
}
//修改购物车意向商品选择状态
export function checkedCart (params) {
  return server.cart.checkedCart({ params });
}
//根据供应商id查询商品信息
export function selectByClientId (params) {
  return server.goodsInfo.selectByClientId(params)
}
//根据id查询店铺信息
export function selectBrandById (params) {
  return server.brand.selectBrandById(params)
}
//创建订单
export function createOrder (param) {
  return server.order.createOrder(param);
}
//提交订单
export function submitOrder (param) {
  return server.order.submitOrder(param);
}
//立即购买
export function buyNowGoods (param) {
  return server.order.buyNowGoods(param);
}
//立即购买订单提交
export function submitBuyNow (param) {
  return server.order.submitBuyNow(param);
}
//订单信息
export function detailOrder (params) {
  return server.order.detailOrder({ params });
}
//订单状态修改
export function changeOrderStatus (param) {
  return server.order.changeOrderStatus(param);
}

//根据id查询企业信息
export function findClientInfoById (params) {
  return server.clientInfo.findClientInfoById(params);
}
//根据id查询企业信息
export function selectIsAutionById (params) {
  return server.autionInfo.selectIsAutionById(params);
}
//创建竞买，竞卖订单
export function createReqOrder (params) {
  return server.proProduct.createReqOrder(params);
}
// 获取操作记录
export function getOperatingRecord (params) {
  return server.order.getOperatingRecord({ params });
}


//用户资料
export function selectByPrimaryKey (params) {
  return server.clientInfo.selectByPrimaryKey({ params })
}

//竞卖完善订单获取供应商买家信息
export function autionDetail (params) {
  return server.autionDeal.autionDetail({ params })
}
//判断商品是否是自家的
export function isMyGoods (params) {
  return server.goodsInfo.isMyGoods({ params });
}

//南海灵通铝锭最新价格获取
export function newestLvdingPrice () {
  return server.lvDingPrice.newestLvdingPrice()
}

//南海灵通铝锭最新价格获取
export function getInvoiceInfo (params) {
  return server.clientInfo.getInvoiceInfo({ params })
}
